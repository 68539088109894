/**
 * keycloak setup
 */
import Keycloak, { KeycloakError } from 'keycloak-js';
import axios from 'axios';
import log from 'loglevel';
import { LogLevel, faro } from '@grafana/faro-react';

import { environmentHelperInstance } from './EnvironmentHelper';
import EventBus, { Events } from '@dexit/common/utils/EventBus';

class KeycloakHelper {
	keycloakInstance: Keycloak;
	authError?: KeycloakError;

	constructor() {
		this.keycloakInstance = new Keycloak({
			clientId: environmentHelperInstance.clientId,
			realm: environmentHelperInstance.tenant,
			url: window.AUTH_URL
				? window.AUTH_URL
				: `https://auth.314ecorp.${environmentHelperInstance.env == 'us' ? 'com' : environmentHelperInstance.env}/auth`,
		});

		this.keycloakInstance.onAuthError = (error: KeycloakError) => {
			log.error('Auth error', error);
			this.authError = error;
			EventBus.publish(Events.AUTHENTICATION_FAILURE);
		};

		this.keycloakInstance.onAuthSuccess = () => {
			this.authError = undefined;
			EventBus.publish(Events.AUTHENTICATION_SUCCESS);
		};
	}

	async init() {
		if (this.keycloakInstance) {
			const authenticated = await this.keycloakInstance.init({
				onLoad: 'login-required',
				checkLoginIframe: false,
				token: '',
			});
			log.info('authenticated', authenticated);
		} else {
			log.error('Keycloak is not initiated');
		}
	}

	refreshToken = async (tokenResponse: Record<string, string>, tokenUri: string) => {
		try {
			const { data } = await axios.post(
				tokenUri,
				{ grant_type: 'refresh_token', refresh_token: tokenResponse.refresh_token },
				{
					headers: {
						Authorization: `Bearer ${tokenResponse.access_token}`,
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				},
			);
			return data;
		} catch (e) {
			log.error('error while refresh ', e);

			return {};
		}
	};

	getToken = async (minValidity = 5): Promise<string> => {
		if (this.keycloakInstance) {
			await this.keycloakInstance.updateToken(minValidity);
			const token = this.keycloakInstance.token;
			return token ?? '';
		}
		return '';
	};

	clearToken = () => {
		this.keycloakInstance?.clearToken();
	};

	logout = async () => {
		await this.keycloakInstance?.logout().catch((ex) => {
			log.error('Failed to logout', ex);
			faro.api.pushLog(['Failed to logout', ex], { level: LogLevel.ERROR });
		});
	};
}

const standaloneHelperInstance = new KeycloakHelper();
export { standaloneHelperInstance };
