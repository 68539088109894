/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Modal to Create Batch
 */

import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, Card, Form, Modal, Row, Space, Typography, Upload } from 'antd';
import { CloseCircleFilled, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { RuleObject } from 'antd/lib/form';
import { UploadFile } from 'antd/es/upload/interface';

import BatchForm from '../batch-scan/BatchForm';
import EventBus, { Events } from '@dexit/common/utils/EventBus';
import matomoconfig from 'configs/matomo.config';
import useCreateBatch from 'hooks/mutations/useCreateBatch';
import useMatomo from '@dexit/admin/src/matomo/useMatomo';
import { BatchRequestModel } from '@dexit/common/openapi';
import { FileGroups, fileGroups, getFileIcon } from 'components/document/utils';
import { Status } from 'store/upload';

const { Dragger } = Upload;
const { Text } = Typography;

interface IProps {
	batchID?: string;
	queueId?: string;
	open: boolean;
	onModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImportLocal: React.FC<IProps> = ({ batchID, queueId, open, onModalOpen }) => {
	const [form] = Form.useForm();
	const { trackEvent } = useMatomo();
	const [files, setFiles] = useState<UploadFile[]>([]);
	const [disabled, setDisabled] = useState(true);

	const createBatch = useCreateBatch();

	const isDifferentFileTypes = useMemo(() => {
		if (files.length <= 1) {
			return false;
		}

		const fileTypes = _.uniq(_.map(files, (file) => _.lowerCase(file.name?.split('.').pop())));
		const allTypesInSameGroup =
			fileTypes.length === 1
				? true
				: _.some(fileGroups, (extensions) => _.isEqual(_.intersection(fileTypes, extensions), fileTypes));

		return !allTypesInSameGroup;
	}, [files]);

	const closeModal = () => {
		form.resetFields();
		onModalOpen(false);
		setFiles([]);
	};

	const handleSubmit = (batchId?: string) => {
		EventBus.publish(Events.FILE_UPLOAD_REQ, {
			batchId,
			queueId: queueId ?? form.getFieldValue('queue'),
			filesParam: files,
		});
		closeModal();
		trackEvent({
			category: 'Import - Local',
			action: matomoconfig.actions.UPLOAD,
			name: 'Uploaded file',
		});
	};

	const handleBatchCreate = (values: any) => {
		if (batchID) {
			handleSubmit(batchID);
		} else {
			const { queue } = values;
			const payload: BatchRequestModel = {
				id: batchID,
				pages: 0,
				source: 'Upload',
				queue,
				filetype:
					(_.findKey(fileGroups, (extensions) =>
						_.includes(extensions, _.toLower(files[0].name?.split('.').pop())),
					) as FileGroups) ?? FileGroups.Other,
				status: Status.inProgress, // TODO: remove this once we have the correct status for uploading
			};
			createBatch.mutate(
				{ queueId: queue, batchRequestModel: payload },
				{
					onSuccess: (data) => handleSubmit(batchID ?? data.id),
				},
			);
		}
	};

	const validator = async (__: RuleObject, val: any) => {
		if (!val) {
			return Promise.resolve();
		} else if (!val.fileList || _.isEmpty(val.fileList)) {
			return Promise.reject('Please upload a file');
		}
		return Promise.resolve();
	};

	const itemRender = (file: UploadFile, remove: () => void) => (
		<Row>
			<div className='flex-space-between' style={{ gap: '8px' }}>
				{getFileIcon(file.name, true)}
				<Text ellipsis={{ tooltip: file.name }} className='full-width'>
					{file.name}
				</Text>
				<Button danger type='text' icon={<DeleteOutlined />} onClick={remove} />
			</div>
		</Row>
	);

	return (
		<Modal
			maskClosable={false}
			destroyOnClose
			title='Upload files'
			open={open}
			width={800}
			okText='Upload'
			okButtonProps={{
				type: 'primary',
				disabled: _.isEmpty(files) || disabled || isDifferentFileTypes,
				loading: createBatch.isLoading,
			}}
			cancelButtonProps={{ type: 'text', onClick: closeModal }}
			onOk={form.submit}
			onCancel={closeModal}
		>
			<Space direction='vertical' className='full-width'>
				{isDifferentFileTypes && (
					<Card className='error-card' size='small'>
						<Space>
							<CloseCircleFilled style={{ color: '#FF4D4F' }} />
							Import failed for some files. Please select only one file type at a time.
						</Space>
					</Card>
				)}
				<Form
					name='Upload Form'
					layout='vertical'
					form={form}
					onFieldsChange={() => setDisabled(false)}
					onFinish={handleBatchCreate}
				>
					<div style={{ marginBottom: '5%' }}>
						<Form.Item
							name='uploadFiles'
							rules={[{ required: true, message: 'Please upload a file' }, { validator }]}
						>
							<Dragger
								beforeUpload={() => false}
								fileList={files}
								multiple={true}
								itemRender={(_originNode, file: UploadFile, _fileList, { remove }) =>
									itemRender(file, remove)
								}
								onChange={({ fileList }) => {
									setFiles(fileList);
									setDisabled(false);
								}}
							>
								<p className='ant-upload-drag-icon'>
									<InboxOutlined />
								</p>
								<p className='ant-upload-text'>Click or drag file(s) to this area to upload</p>
							</Dragger>
						</Form.Item>
					</div>
					<BatchForm queueId={queueId} fileList={files} />
				</Form>
			</Space>
		</Modal>
	);
};

export default ImportLocal;
