/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Get the presigned URL to display in Preview
 */

import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import api from 'client/index';
import EventBus, { Events, Messages } from '@dexit/common/utils/EventBus';
import { PresignedUrlViewResponseModel } from '@dexit/common/openapi';

const QUERY_PRE_SIGNED_URL = 'query-presignedurl';

const usePreSignedUrl = (
	filePath?: string,
	options?: UseQueryOptions<
		Promise<PresignedUrlViewResponseModel>,
		unknown,
		PresignedUrlViewResponseModel,
		(string | undefined)[]
	>,
): UseQueryResult<PresignedUrlViewResponseModel, unknown> => {
	return useQuery({
		queryKey: [QUERY_PRE_SIGNED_URL, filePath],
		queryFn: async () => {
			if (!filePath) {
				throw new Error('filePath Not Found');
			}
			const { data } = await api.DocumentApi.presignedURLview(filePath);
			return data;
		},
		...options,
		onError: () => {
			EventBus.publish(Events.M_Error, Messages.Error);
		},
	});
};

export default usePreSignedUrl;
