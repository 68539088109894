/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ResponseModel } from '../Models';
// @ts-ignore
import { SendUpdateRequestModel } from '../Models';
// @ts-ignore
import { TriggerWorkflowRequestModel } from '../Models';
// @ts-ignore
import { WorkflowDetailResponseModel } from '../Models';
// @ts-ignore
import { WorkflowErrorQueueResponseModel } from '../Models';
// @ts-ignore
import { WorkflowHistoryResponseModel } from '../Models';
// @ts-ignore
import { WorkflowRequestModel } from '../Models';
// @ts-ignore
import { WorkflowResponseModel } from '../Models';
// @ts-ignore
import { WorkflowTasksRequestModel } from '../Models';
// @ts-ignore
import { WorkflowTasksResponseModel } from '../Models';
/**
 * WorkflowApi - axios parameter creator
 * @export
 */
export const WorkflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkflowRequestModel} workflowRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow: async (workflowRequestModel: WorkflowRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowRequestModel' is not null or undefined
            assertParamExists('createWorkflow', 'workflowRequestModel', workflowRequestModel)
            const localVarPath = `/api/v1/workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workflowRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteErrorQueueById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteErrorQueueById', 'id', id)
            const localVarPath = `/api/v1/workflow/deleteErrorQueueById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWorkflowById', 'id', id)
            const localVarPath = `/api/v1/workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorQueues: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workflow/getErrorQueues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryOfWorkflows: async (workflowRunId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowRunId' is not null or undefined
            assertParamExists('getHistoryOfWorkflows', 'workflowRunId', workflowRunId)
            const localVarPath = `/api/v1/workflow/getHistoryOfWorkflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (workflowRunId !== undefined) {
                localVarQueryParameter['workflow_run_id'] = workflowRunId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkflowTasksRequestModel} workflowTasksRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks: async (workflowTasksRequestModel: WorkflowTasksRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowTasksRequestModel' is not null or undefined
            assertParamExists('getTasks', 'workflowTasksRequestModel', workflowTasksRequestModel)
            const localVarPath = `/api/v1/workflow/getTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workflowTasksRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [issystem] 
         * @param {string} [createdby] 
         * @param {string} [createddate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowById: async (id?: string, issystem?: boolean, createdby?: string, createddate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (issystem !== undefined) {
                localVarQueryParameter['issystem'] = issystem;
            }

            if (createdby !== undefined) {
                localVarQueryParameter['createdby'] = createdby;
            }

            if (createddate !== undefined) {
                localVarQueryParameter['createddate'] = (createddate as any instanceof Date) ?
                    (createddate as any).toISOString() :
                    createddate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dexitWorkflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowDetail: async (dexitWorkflowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dexitWorkflowId' is not null or undefined
            assertParamExists('getWorkflowDetail', 'dexitWorkflowId', dexitWorkflowId)
            const localVarPath = `/api/v1/workflow/getWorkflowDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (dexitWorkflowId !== undefined) {
                localVarQueryParameter['dexit_workflow_id'] = dexitWorkflowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendUpdateRequestModel} sendUpdateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUpdate: async (sendUpdateRequestModel: SendUpdateRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendUpdateRequestModel' is not null or undefined
            assertParamExists('sendUpdate', 'sendUpdateRequestModel', sendUpdateRequestModel)
            const localVarPath = `/api/v1/workflow/sendUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendUpdateRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateWorkflow: async (workflowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('terminateWorkflow', 'workflowId', workflowId)
            const localVarPath = `/api/v1/workflow/terminateWorkflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (workflowId !== undefined) {
                localVarQueryParameter['workflow_id'] = workflowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TriggerWorkflowRequestModel} triggerWorkflowRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerWorkflow: async (triggerWorkflowRequestModel: TriggerWorkflowRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'triggerWorkflowRequestModel' is not null or undefined
            assertParamExists('triggerWorkflow', 'triggerWorkflowRequestModel', triggerWorkflowRequestModel)
            const localVarPath = `/api/v1/workflow/triggerWorkflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(triggerWorkflowRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowApi - functional programming interface
 * @export
 */
export const WorkflowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkflowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkflowRequestModel} workflowRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflow(workflowRequestModel: WorkflowRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflow(workflowRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.createWorkflow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteErrorQueueById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteErrorQueueById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.deleteErrorQueueById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflowById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflowById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.deleteWorkflowById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getErrorQueues(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowErrorQueueResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getErrorQueues(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.getErrorQueues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoryOfWorkflows(workflowRunId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowHistoryResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoryOfWorkflows(workflowRunId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.getHistoryOfWorkflows']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkflowTasksRequestModel} workflowTasksRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTasks(workflowTasksRequestModel: WorkflowTasksRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowTasksResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasks(workflowTasksRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.getTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [issystem] 
         * @param {string} [createdby] 
         * @param {string} [createddate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowById(id?: string, issystem?: boolean, createdby?: string, createddate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowById(id, issystem, createdby, createddate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.getWorkflowById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} dexitWorkflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowDetail(dexitWorkflowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowDetailResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowDetail(dexitWorkflowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.getWorkflowDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SendUpdateRequestModel} sendUpdateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendUpdate(sendUpdateRequestModel: SendUpdateRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendUpdate(sendUpdateRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.sendUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminateWorkflow(workflowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminateWorkflow(workflowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.terminateWorkflow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TriggerWorkflowRequestModel} triggerWorkflowRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerWorkflow(triggerWorkflowRequestModel: TriggerWorkflowRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerWorkflow(triggerWorkflowRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.triggerWorkflow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkflowApi - factory interface
 * @export
 */
export const WorkflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkflowApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkflowRequestModel} workflowRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow(workflowRequestModel: WorkflowRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.createWorkflow(workflowRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteErrorQueueById(id: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteErrorQueueById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowById(id: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.deleteWorkflowById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorQueues(options?: any): AxiosPromise<Array<WorkflowErrorQueueResponseModel>> {
            return localVarFp.getErrorQueues(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryOfWorkflows(workflowRunId: string, options?: any): AxiosPromise<WorkflowHistoryResponseModel> {
            return localVarFp.getHistoryOfWorkflows(workflowRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkflowTasksRequestModel} workflowTasksRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(workflowTasksRequestModel: WorkflowTasksRequestModel, options?: any): AxiosPromise<Array<WorkflowTasksResponseModel>> {
            return localVarFp.getTasks(workflowTasksRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [issystem] 
         * @param {string} [createdby] 
         * @param {string} [createddate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowById(id?: string, issystem?: boolean, createdby?: string, createddate?: string, options?: any): AxiosPromise<Array<WorkflowResponseModel>> {
            return localVarFp.getWorkflowById(id, issystem, createdby, createddate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dexitWorkflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowDetail(dexitWorkflowId: string, options?: any): AxiosPromise<Array<WorkflowDetailResponseModel>> {
            return localVarFp.getWorkflowDetail(dexitWorkflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendUpdateRequestModel} sendUpdateRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUpdate(sendUpdateRequestModel: SendUpdateRequestModel, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.sendUpdate(sendUpdateRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateWorkflow(workflowId: string, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.terminateWorkflow(workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TriggerWorkflowRequestModel} triggerWorkflowRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerWorkflow(triggerWorkflowRequestModel: TriggerWorkflowRequestModel, options?: any): AxiosPromise<ResponseModel> {
            return localVarFp.triggerWorkflow(triggerWorkflowRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkflowApi - interface
 * @export
 * @interface WorkflowApi
 */
export interface WorkflowApiInterface {
    /**
     * 
     * @param {WorkflowRequestModel} workflowRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    createWorkflow(workflowRequestModel: WorkflowRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    deleteErrorQueueById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    deleteWorkflowById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    getErrorQueues(options?: RawAxiosRequestConfig): AxiosPromise<Array<WorkflowErrorQueueResponseModel>>;

    /**
     * 
     * @param {string} workflowRunId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    getHistoryOfWorkflows(workflowRunId: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowHistoryResponseModel>;

    /**
     * 
     * @param {WorkflowTasksRequestModel} workflowTasksRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    getTasks(workflowTasksRequestModel: WorkflowTasksRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<Array<WorkflowTasksResponseModel>>;

    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [issystem] 
     * @param {string} [createdby] 
     * @param {string} [createddate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    getWorkflowById(id?: string, issystem?: boolean, createdby?: string, createddate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<WorkflowResponseModel>>;

    /**
     * 
     * @param {string} dexitWorkflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    getWorkflowDetail(dexitWorkflowId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<WorkflowDetailResponseModel>>;

    /**
     * 
     * @param {SendUpdateRequestModel} sendUpdateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    sendUpdate(sendUpdateRequestModel: SendUpdateRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }>;

    /**
     * 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    terminateWorkflow(workflowId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

    /**
     * 
     * @param {TriggerWorkflowRequestModel} triggerWorkflowRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    triggerWorkflow(triggerWorkflowRequestModel: TriggerWorkflowRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<ResponseModel>;

}

/**
 * WorkflowApi - object-oriented interface
 * @export
 * @class WorkflowApi
 * @extends {BaseAPI}
 */
export class WorkflowApi extends BaseAPI implements WorkflowApiInterface {
    /**
     * 
     * @param {WorkflowRequestModel} workflowRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public createWorkflow(workflowRequestModel: WorkflowRequestModel, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).createWorkflow(workflowRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public deleteErrorQueueById(id: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).deleteErrorQueueById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public deleteWorkflowById(id: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).deleteWorkflowById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public getErrorQueues(options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).getErrorQueues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowRunId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public getHistoryOfWorkflows(workflowRunId: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).getHistoryOfWorkflows(workflowRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowTasksRequestModel} workflowTasksRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public getTasks(workflowTasksRequestModel: WorkflowTasksRequestModel, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).getTasks(workflowTasksRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [issystem] 
     * @param {string} [createdby] 
     * @param {string} [createddate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public getWorkflowById(id?: string, issystem?: boolean, createdby?: string, createddate?: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).getWorkflowById(id, issystem, createdby, createddate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dexitWorkflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public getWorkflowDetail(dexitWorkflowId: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).getWorkflowDetail(dexitWorkflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendUpdateRequestModel} sendUpdateRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public sendUpdate(sendUpdateRequestModel: SendUpdateRequestModel, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).sendUpdate(sendUpdateRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public terminateWorkflow(workflowId: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).terminateWorkflow(workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TriggerWorkflowRequestModel} triggerWorkflowRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public triggerWorkflow(triggerWorkflowRequestModel: TriggerWorkflowRequestModel, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).triggerWorkflow(triggerWorkflowRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

