/* tslint:disable */
/* eslint-disable */
/**
 * DexIt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AdvanceEncounterSearchRequestModel } from '../Models';
// @ts-ignore
import { AdvancePatientSearchRequestModel } from '../Models';
// @ts-ignore
import { EncounterSearchResponseModel } from '../Models';
// @ts-ignore
import { PatientSearchRequestModel } from '../Models';
// @ts-ignore
import { PatientSearchResponseModel } from '../Models';
// @ts-ignore
import { PatientSystemCodeResponseModel } from '../Models';
/**
 * ResourceApi - axios parameter creator
 * @export
 */
export const ResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdvanceEncounterSearchRequestModel} advanceEncounterSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advanceEncounterSearch: async (advanceEncounterSearchRequestModel: AdvanceEncounterSearchRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advanceEncounterSearchRequestModel' is not null or undefined
            assertParamExists('advanceEncounterSearch', 'advanceEncounterSearchRequestModel', advanceEncounterSearchRequestModel)
            const localVarPath = `/api/v1/resource/advanceEncounterSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advanceEncounterSearchRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdvancePatientSearchRequestModel} advancePatientSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancePatientSearch: async (advancePatientSearchRequestModel: AdvancePatientSearchRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advancePatientSearchRequestModel' is not null or undefined
            assertParamExists('advancePatientSearch', 'advancePatientSearchRequestModel', advancePatientSearchRequestModel)
            const localVarPath = `/api/v1/resource/advancePatientSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advancePatientSearchRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIdentifierSystems: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/resource/getAllIdentifierSystems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatientSearchRequestModel} patientSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientSearch: async (patientSearchRequestModel: PatientSearchRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientSearchRequestModel' is not null or undefined
            assertParamExists('patientSearch', 'patientSearchRequestModel', patientSearchRequestModel)
            const localVarPath = `/api/v1/resource/patientSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientSearchRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hl7Type 
         * @param {string} hl7Message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receivedHl7Message: async (hl7Type: string, hl7Message: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hl7Type' is not null or undefined
            assertParamExists('receivedHl7Message', 'hl7Type', hl7Type)
            // verify required parameter 'hl7Message' is not null or undefined
            assertParamExists('receivedHl7Message', 'hl7Message', hl7Message)
            const localVarPath = `/public/api/v1/resource/receivedHl7Message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (hl7Type !== undefined) {
                localVarQueryParameter['hl7_type'] = hl7Type;
            }

            if (hl7Message !== undefined) {
                localVarQueryParameter['hl7_message'] = hl7Message;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universalEncounterSearch: async (searchTerm: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('universalEncounterSearch', 'searchTerm', searchTerm)
            const localVarPath = `/api/v1/resource/universalEncounterSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['search_term'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universalPatientSearch: async (searchTerm: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('universalPatientSearch', 'searchTerm', searchTerm)
            const localVarPath = `/api/v1/resource/universalPatientSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile", "openid"], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['search_term'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceApi - functional programming interface
 * @export
 */
export const ResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdvanceEncounterSearchRequestModel} advanceEncounterSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advanceEncounterSearch(advanceEncounterSearchRequestModel: AdvanceEncounterSearchRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EncounterSearchResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advanceEncounterSearch(advanceEncounterSearchRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ResourceApi.advanceEncounterSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdvancePatientSearchRequestModel} advancePatientSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancePatientSearch(advancePatientSearchRequestModel: AdvancePatientSearchRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientSearchResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancePatientSearch(advancePatientSearchRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ResourceApi.advancePatientSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllIdentifierSystems(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientSystemCodeResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllIdentifierSystems(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ResourceApi.getAllIdentifierSystems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PatientSearchRequestModel} patientSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientSearch(patientSearchRequestModel: PatientSearchRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientSearchResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientSearch(patientSearchRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ResourceApi.patientSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} hl7Type 
         * @param {string} hl7Message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receivedHl7Message(hl7Type: string, hl7Message: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receivedHl7Message(hl7Type, hl7Message, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ResourceApi.receivedHl7Message']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async universalEncounterSearch(searchTerm: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EncounterSearchResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.universalEncounterSearch(searchTerm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ResourceApi.universalEncounterSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async universalPatientSearch(searchTerm: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientSearchResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.universalPatientSearch(searchTerm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ResourceApi.universalPatientSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ResourceApi - factory interface
 * @export
 */
export const ResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {AdvanceEncounterSearchRequestModel} advanceEncounterSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advanceEncounterSearch(advanceEncounterSearchRequestModel: AdvanceEncounterSearchRequestModel, options?: any): AxiosPromise<Array<EncounterSearchResponseModel>> {
            return localVarFp.advanceEncounterSearch(advanceEncounterSearchRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdvancePatientSearchRequestModel} advancePatientSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancePatientSearch(advancePatientSearchRequestModel: AdvancePatientSearchRequestModel, options?: any): AxiosPromise<Array<PatientSearchResponseModel>> {
            return localVarFp.advancePatientSearch(advancePatientSearchRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIdentifierSystems(options?: any): AxiosPromise<Array<PatientSystemCodeResponseModel>> {
            return localVarFp.getAllIdentifierSystems(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatientSearchRequestModel} patientSearchRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientSearch(patientSearchRequestModel: PatientSearchRequestModel, options?: any): AxiosPromise<Array<PatientSearchResponseModel>> {
            return localVarFp.patientSearch(patientSearchRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hl7Type 
         * @param {string} hl7Message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receivedHl7Message(hl7Type: string, hl7Message: string, options?: any): AxiosPromise<void> {
            return localVarFp.receivedHl7Message(hl7Type, hl7Message, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universalEncounterSearch(searchTerm: string, options?: any): AxiosPromise<Array<EncounterSearchResponseModel>> {
            return localVarFp.universalEncounterSearch(searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universalPatientSearch(searchTerm: string, options?: any): AxiosPromise<Array<PatientSearchResponseModel>> {
            return localVarFp.universalPatientSearch(searchTerm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResourceApi - interface
 * @export
 * @interface ResourceApi
 */
export interface ResourceApiInterface {
    /**
     * 
     * @param {AdvanceEncounterSearchRequestModel} advanceEncounterSearchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApiInterface
     */
    advanceEncounterSearch(advanceEncounterSearchRequestModel: AdvanceEncounterSearchRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<Array<EncounterSearchResponseModel>>;

    /**
     * 
     * @param {AdvancePatientSearchRequestModel} advancePatientSearchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApiInterface
     */
    advancePatientSearch(advancePatientSearchRequestModel: AdvancePatientSearchRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<Array<PatientSearchResponseModel>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApiInterface
     */
    getAllIdentifierSystems(options?: RawAxiosRequestConfig): AxiosPromise<Array<PatientSystemCodeResponseModel>>;

    /**
     * 
     * @param {PatientSearchRequestModel} patientSearchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApiInterface
     */
    patientSearch(patientSearchRequestModel: PatientSearchRequestModel, options?: RawAxiosRequestConfig): AxiosPromise<Array<PatientSearchResponseModel>>;

    /**
     * 
     * @param {string} hl7Type 
     * @param {string} hl7Message 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApiInterface
     */
    receivedHl7Message(hl7Type: string, hl7Message: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} searchTerm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApiInterface
     */
    universalEncounterSearch(searchTerm: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EncounterSearchResponseModel>>;

    /**
     * 
     * @param {string} searchTerm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApiInterface
     */
    universalPatientSearch(searchTerm: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PatientSearchResponseModel>>;

}

/**
 * ResourceApi - object-oriented interface
 * @export
 * @class ResourceApi
 * @extends {BaseAPI}
 */
export class ResourceApi extends BaseAPI implements ResourceApiInterface {
    /**
     * 
     * @param {AdvanceEncounterSearchRequestModel} advanceEncounterSearchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public advanceEncounterSearch(advanceEncounterSearchRequestModel: AdvanceEncounterSearchRequestModel, options?: RawAxiosRequestConfig) {
        return ResourceApiFp(this.configuration).advanceEncounterSearch(advanceEncounterSearchRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdvancePatientSearchRequestModel} advancePatientSearchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public advancePatientSearch(advancePatientSearchRequestModel: AdvancePatientSearchRequestModel, options?: RawAxiosRequestConfig) {
        return ResourceApiFp(this.configuration).advancePatientSearch(advancePatientSearchRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public getAllIdentifierSystems(options?: RawAxiosRequestConfig) {
        return ResourceApiFp(this.configuration).getAllIdentifierSystems(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatientSearchRequestModel} patientSearchRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public patientSearch(patientSearchRequestModel: PatientSearchRequestModel, options?: RawAxiosRequestConfig) {
        return ResourceApiFp(this.configuration).patientSearch(patientSearchRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hl7Type 
     * @param {string} hl7Message 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public receivedHl7Message(hl7Type: string, hl7Message: string, options?: RawAxiosRequestConfig) {
        return ResourceApiFp(this.configuration).receivedHl7Message(hl7Type, hl7Message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchTerm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public universalEncounterSearch(searchTerm: string, options?: RawAxiosRequestConfig) {
        return ResourceApiFp(this.configuration).universalEncounterSearch(searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchTerm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public universalPatientSearch(searchTerm: string, options?: RawAxiosRequestConfig) {
        return ResourceApiFp(this.configuration).universalPatientSearch(searchTerm, options).then((request) => request(this.axios, this.basePath));
    }
}

