/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description universal search constants
 */

enum ENTITY {
	TEXT = 'text',
	SAVED_SEARCH = 'saved-search',
	ALL = 'all',
	DOCUMENT_TYPES = 'documenttype',
	PATIENTS = 'patient',
	ENCOUNTERS = 'encounter',
	USERS = 'user',
}

enum TABS {
	INDEX_INFO = 'index-info',
	DEFICIENCIES = 'deficiencies',
}

export { ENTITY, TABS };
